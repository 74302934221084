import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Box,
    Skeleton,
    Button
} from '@mui/material';
import PropTypes from 'prop-types';
import TemplateCard from 'views/dashboard/TemplateCard';
import SurveyService from 'services/survey.service';
import { GridCloseIcon } from '@mui/x-data-grid';
import { Visibility, ThumbUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SurveyPreviewModal from './survey-preview-modal';

const SurveyTypeSelectorModal = ({ open, onClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [previewModal, setPreviewModal] = useState({ open: false, survey: null });
    const navigate = useNavigate();
    const cloneTemplate = async (surveyToken) => {
        const { data } = await SurveyService.cloneTemplate(surveyToken);
        navigate(`/surveys/${data.survey_token}/edit`);
    };

    const handlePreviewClick = (survey) => {
        console.log('DATADOG: Survey -> ', survey);
        setPreviewModal({ open: true, survey });
    };

    const handlePreviewClose = () => {
        setPreviewModal({ open: false, survey: null });
    };

    const fetchTemplates = async (keyword = '') => {
        setIsLoading(true);
        try {
            setTemplates([]);
            const response = keyword ? await SurveyService.getTemplateSearch(keyword) : await SurveyService.getTemplate('');
            const newTemplates = response.data.data || [];
            setTemplates(newTemplates);
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchTemplates(searchTerm);
        }, 300); // Add 300ms delay to prevent too many API calls

        return () => clearTimeout(debounceTimer);
    }, [searchTerm]);

    const renderLoadingSkeletons = () => (
        <Grid container spacing={3}>
            {[...Array(12)].map((_, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Skeleton variant="rectangular" height={160} sx={{ borderRadius: 2 }} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const renderContent = () => {
        if (isLoading) {
            return renderLoadingSkeletons();
        }

        if (templates.length === 0 && searchTerm) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '300px',
                        textAlign: 'center',
                        padding: '20px'
                    }}
                >
                    <Typography variant="h4" sx={{ color: '#666666', marginBottom: 2 }}>
                        No templates found
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#888888' }}>
                        Try adjusting your search or check back later for new templates
                    </Typography>
                </Box>
            );
        }

        return (
            <Grid container>
                {/* Blank Template Card */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card
                        sx={{
                            cursor: 'pointer',
                            position: 'relative',
                            transform: 'scale(0.95)',
                            borderRadius: '16px',
                            boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
                            }
                        }}
                        onClick={() => {
                            navigate('/surveys/add');
                            onClose();
                        }}
                    >
                        <div style={{ height: '160px', position: 'relative', padding: '12px' }}>
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '2px dashed #e0e0e0',
                                    borderRadius: '12px',
                                    backgroundColor: '#f8f8f8'
                                }}
                            >
                                <Typography variant="h6" sx={{ color: '#666666' }}>
                                    Start from scratch
                                </Typography>
                            </Box>
                        </div>
                        <CardContent sx={{ padding: '16px !important' }}>
                            <Typography
                                sx={{
                                    fontSize: '0.925rem',
                                    fontWeight: 600,
                                    color: '#2c3e50'
                                }}
                            >
                                Blank Survey
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {templates.map((survey) => (
                    <Grid item xs={12} sm={6} md={3} key={survey?.id}>
                        <Card
                            sx={{
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                position: 'relative',
                                transform: 'scale(0.95)',
                                borderRadius: '16px',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
                                backgroundColor: '#ffffff',
                                '&:hover': {
                                    transform: 'scale(1)',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                                    '& .hover-overlay': {
                                        opacity: 1
                                    }
                                }
                            }}
                        >
                            <div style={{ height: '160px', position: 'relative', padding: '12px' }}>
                                <CardMedia
                                    component="img"
                                    image={survey?.meta?.template_cover_image ?? ''}
                                    alt={survey?.title}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '12px'
                                    }}
                                />
                                <Box
                                    className="hover-overlay"
                                    sx={{
                                        position: 'absolute',
                                        top: '12px',
                                        left: '12px',
                                        right: '12px',
                                        bottom: '0',
                                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                                        backdropFilter: 'blur(4px)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 2,
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease',
                                        borderRadius: '12px'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Visibility />}
                                        sx={{
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            boxShadow: 'none'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlePreviewClick(survey);
                                        }}
                                    >
                                        Preview
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<ThumbUp />}
                                        sx={{
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            boxShadow: 'none'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onClose();
                                            cloneTemplate(survey?.survey_token);
                                        }}
                                    >
                                        Use It
                                    </Button>
                                </Box>
                            </div>
                            <CardContent sx={{ padding: '16px !important' }}>
                                <Typography
                                    sx={{
                                        fontSize: '0.925rem',
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#2c3e50'
                                    }}
                                >
                                    {survey?.survey_title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        height: '90vh', // Set dialog to 90% of viewport height
                        maxHeight: '900px' // Maximum height limit
                    }
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px', position: 'relative' }}>
                    <DialogTitle sx={{ textAlign: 'center', width: '100%' }}>
                        <Typography variant="h2">Select a template</Typography>
                        <Typography variant="p" sx={{ fontWeight: 400, fontSize: '14px', color: '#666666' }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={onClose}
                        size="large"
                        style={{ padding: '8px 10px', position: 'absolute', right: '30px', borderRadius: '0', top: '30px' }}
                        aria-label="close"
                    >
                        <GridCloseIcon />
                    </IconButton>
                </div>

                <div style={{ padding: '0 24px 16px' }}>
                    <TextField
                        placeholder="Search over 500+ templates..."
                        fullWidth
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            sx: {
                                borderRadius: '8px',
                                border: '1px solid #E0E0E0',
                                backgroundColor: '#f5f5f5',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }
                        }}
                        sx={{
                            marginBottom: '24px',
                            '& .MuiInputBase-input': {
                                padding: '16px 20px'
                            }
                        }}
                    />

                    <DialogContent
                        style={{
                            padding: 0,
                            height: '100%',
                            overflow: 'auto' // Enable scrolling
                        }}
                    >
                        {renderContent()}
                    </DialogContent>
                </div>
            </Dialog>
            <SurveyPreviewModal open={previewModal.open} onClose={handlePreviewClose} survey={previewModal.survey} />
        </>
    );
};

SurveyTypeSelectorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default SurveyTypeSelectorModal;
