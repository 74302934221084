/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Alert,
    AlertTitle,
    LinearProgress
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    AssignmentTurnedIn,
    TrendingUp,
    People,
    AttachMoney,
    EmojiEvents,
    ManageAccounts,
    LiveHelp,
    QuestionAnswer,
    CloudDownload
} from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import SurveyService from 'services/survey.service';
import MiscService from 'services/misc.service';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';
import { IconChartBar, IconNote, IconServerOff, IconTrophy } from '@tabler/icons';
import transformToJqDatatable from 'utils/data-transformers';
import DismissibleAlert from 'views/components/DismissibleAlert';

const DashBoard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [recentSurveys, setRecentSurveys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const columns = React.useRef([
        { field: 'survey_title' },
        { field: 'start_date' },
        { field: 'end_date' },
        { field: 'quota' },
        { field: 'created_at' },
        { field: 'survey_status' },
        { field: 'survey_token' }
    ]);

    const [paginationProp, setPaginationProp] = React.useState({
        direction: true,
        sortBy: 'created_at',
        searchBy: 'survey_title',
        page: 0,
        rowsPerPage: 5,
        rowsNumber: 5,
        filter: null,
        singleFilterValue: null
    });

    const fetchData = useCallback(async () => {
        try {
            const params = transformToJqDatatable({ columns: columns.current, pagination: paginationProp });
            const [dashboardResponse, surveysResponse] = await Promise.all([
                MiscService.getDashboardData(user?.id),
                SurveyService.list(params)
            ]);
            setDashboardData(dashboardResponse.data);
            setRecentSurveys(surveysResponse.data.data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [user, paginationProp]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const StatItem = ({ icon: Icon, label, value }) => (
        <Grid item xs={12} md={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Icon sx={{ color: 'text.secondary' }} />
                <Box>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        {value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );

    StatItem.propTypes = {
        icon: PropTypes.elementType.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    };

    const QuotaBar = ({ quota }) => {
        const percentageFinder = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return (numerator / denominator) * 100;
        };

        const generateColorOnText = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return `<span style="color: #f76d6d; max-width: '100px'">${numerator}</span> out of <span style="color: #354154">${denominator}</span>`;
        };
        return (
            <Box sx={{ width: '100%', display: 'block' }}>
                <Box>
                    <Typography variant="body2" color="primary" dangerouslySetInnerHTML={{ __html: generateColorOnText(quota) }} />
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={percentageFinder(quota)}
                    color="primary"
                    sx={{
                        width: '100%',
                        height: 5,
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#354154'
                        }
                    }}
                />
            </Box>
        );
    };

    QuotaBar.propTypes = {
        quota: PropTypes.string.isRequired
    };

    const QuickAction = ({ icon: Icon, title, description, action }) => (
        <Box sx={{ mb: 2, mt: '16px' }}>
            <Typography variant="subtitle1" component="h3" gutterBottom>
                <Icon sx={{ verticalAlign: 'bottom' }} /> {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {description}
            </Typography>
            {action}
        </Box>
    );

    QuickAction.propTypes = {
        icon: PropTypes.elementType.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        action: PropTypes.node.isRequired
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                Dashboard Overview
            </Typography>

            <Grid container spacing={4} sx={{ mb: 4 }}>
                <StatItem icon={AssignmentTurnedIn} label="Total Surveys" value={dashboardData.surveys} />
                <StatItem icon={People} label="Total Responses" value={dashboardData.answers} />
                <StatItem icon={AttachMoney} label="Total Spending" value={`$${dashboardData.spending}`} />
                <StatItem icon={TrendingUp} label="Response Rate" value={`${dashboardData.response_rate}%`} />
                <StatItem icon={IconTrophy} label="Response Filled" value={dashboardData.response_filled} />
                <StatItem icon={CloudDownload} label="Responses Remaining" value={dashboardData.response_remained} />
            </Grid>

            <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                Recent Activity
            </Typography>

            <Card>
                <Box sx={{ p: 2 }}>
                    <Grid container sx={{ mb: 2, borderBottom: '1px solid #eee', pb: 1 }}>
                        <Grid item xs={10}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Survey Title
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Quota Available
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'right' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Action
                            </Typography>
                        </Grid>
                    </Grid>

                    {recentSurveys.map((survey) => (
                        <Grid container key={survey.survey_token} sx={{ py: 2, borderBottom: '1px solid #eee' }}>
                            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <IconNote size={20} />
                                <Box>
                                    <Typography
                                        variant="body1"
                                        component={RouterLink}
                                        to={`/${survey.survey_token}/advance-form-create?tab=overview`}
                                        sx={{
                                            color: 'text.primary',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        {survey.survey_title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Status: {survey.survey_status}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <QuotaBar quota={survey.quota || '0 / 0'} />
                            </Grid>
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="text"
                                    component={RouterLink}
                                    to={`/${survey.survey_token}/advance-form-create?tab=overview`}
                                    sx={{ color: 'text.primary' }}
                                >
                                    Manage
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Card>
        </Box>
    );
};

export default DashBoard;
