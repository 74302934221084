import React, { lazy, useEffect } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Typography,
    Grid,
    Button,
    Pagination,
    IconButton,
    Link,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Loadable from 'ui-component/Loadable';
import SurveyService from 'services/survey.service';
import { useParams } from 'react-router-dom';
import ReportService from 'services/report.service';
import { first } from 'lodash';
import QuestionTypeReportGenerator from 'utils/QuestionTypeReportGenerator';
import { IconDownload } from '@tabler/icons';
import { useSnackbar } from 'notistack';
import ListSkeleton from 'views/ListSkeleton';

const ResponseSummary = () => {
    const { surveyToken } = useParams();
    const [responses, setResponses] = React.useState([]);
    const [showMore, setShowMore] = React.useState(false);
    const [userResponses, setUserResponses] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [paginationCurrentPage, setPaginationCurrentPage] = React.useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const [loaderOpen, setLoaderOpen] = React.useState(false);
    const filterQuestionTypes = ['MatrixQuestion', 'MatrixRubricQuestion', 'MatrixDropdownQuestion', 'MatrixDynamicQuestion'];

    const handleLoaderClose = () => setLoaderOpen(false);
    const handleLoaderOpen = () => setLoaderOpen(true);

    const questionTypeComponent = (selectedQuestionTypeIdentifier, data) => {
        try {
            const Component = Loadable(lazy(() => import(`views/preview/summaryQuestionsTypes/${selectedQuestionTypeIdentifier}`)));
            return <Component data={data} />;
        } catch (err) {
            console.error('Error loading question type component:', err);
            return <Alert severity="error">Failed to load question component</Alert>;
        }
    };

    const questionTypeReportGenerator = (selectedQuestionTypeIdentifier, answers, question) => {
        try {
            const typeGenerator = new QuestionTypeReportGenerator(question);
            if (typeof typeGenerator[selectedQuestionTypeIdentifier] === 'function') {
                return typeGenerator[selectedQuestionTypeIdentifier](answers);
            }
            return typeGenerator.GenericQuestion(answers);
        } catch (err) {
            console.error('Error generating question report:', err);
            return 'Unable to generate report for this question type';
        }
    };

    const fetchResponses = async (pageNum = 1) => {
        setIsLoading(true);
        setError(null);
        try {
            const resp = await SurveyService.getDetailedResponse(surveyToken, pageNum);
            if (!resp?.data) throw new Error('No data received');

            setResponses(resp.data);
            setUserResponses(resp.data.respondent_responses ?? null);
        } catch (err) {
            console.error('Error fetching responses:', err);
            setError(err.message || 'Failed to fetch responses');
            enqueueSnackbar('Error loading responses', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async (fileName, surveyId, questionId, respondentId) => {
        handleLoaderOpen();
        try {
            const response = await ReportService.downloadMediaResponse(
                { surveyId, questionId, respondentId },
                { responseType: 'blob', fileName }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response?.config?.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            enqueueSnackbar('File Successfully Downloaded.', { variant: 'success' });
        } catch (err) {
            console.error('Download error:', err);
            enqueueSnackbar('Error occurred while trying to download the file', { variant: 'error' });
        } finally {
            handleLoaderClose();
        }
    };

    const showHideHandler = async () => {
        setShowMore((prev) => !prev);
        setResponses({});
        try {
            if (!showMore) {
                const { data } = await ReportService.fullDetailsDetailedAnswer(surveyToken);
                setResponses(data);
            } else {
                await fetchResponses();
            }
        } catch (err) {
            console.error('Error toggling details:', err);
            enqueueSnackbar('Error loading details', { variant: 'error' });
        }
    };

    useEffect(() => {
        fetchResponses();
        return () => {
            // Cleanup
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return (
            <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                <AlertTitle>Error</AlertTitle>
                {error}
            </Alert>
        );
    }

    const NoResponsesMessage = () => (
        <Alert severity="info" variant="standard">
            <AlertTitle>Info</AlertTitle>
            No public responses available for this survey yet.
        </Alert>
    );

    return (
        <Box sx={{ position: 'relative' }}>
            {isLoading ? (
                <ListSkeleton number={10} />
            ) : (
                <>
                    {responses?.respondent_responses?.total > 0 ? (
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                            <Grid item>
                                <Typography variant="h4">
                                    {first(userResponses?.data)?.respondent?.first_name} {first(userResponses?.data)?.respondent?.last_name}
                                    &apos;s Response
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Pagination
                                    page={paginationCurrentPage}
                                    count={userResponses?.total}
                                    color="primary"
                                    onChange={(_, page) => {
                                        setPaginationCurrentPage(page);
                                        fetchResponses(page);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <NoResponsesMessage />
                    )}

                    {responses && userResponses?.data?.length > 0 && (
                        <Card variant="outlined" sx={{ mt: 2, p: 2 }}>
                            {first(userResponses?.data)
                                ?.answer_texts?.filter((answer) => !filterQuestionTypes.includes(answer?.identifier))
                                ?.map((answer, index) => (
                                    <Grid
                                        container
                                        key={index}
                                        sx={{
                                            backgroundColor: '#F5F5F5',
                                            p: 2,
                                            mb: 2,
                                            borderRadius: 1
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h4" color="text.primary" gutterBottom>
                                                {`${index + 1}. ${answer?.question?.question_title}`}
                                            </Typography>
                                            <Box sx={{ ml: 2 }}>
                                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                                    Answer
                                                </Typography>
                                                <Typography variant="body1">
                                                    {questionTypeReportGenerator(answer?.identifier, answer, answer?.question)}
                                                </Typography>
                                                {answer?.identifier === 'FilesUploadingQuestion' && (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        startIcon={<IconDownload />}
                                                        onClick={() =>
                                                            handleDownload(
                                                                answer?.answer,
                                                                answer?.question?.survey_id,
                                                                answer?.qID,
                                                                first(userResponses?.data)?.respondent_id
                                                            )
                                                        }
                                                        sx={{ mt: 1 }}
                                                    >
                                                        Download
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Card>
                    )}

                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            )}
        </Box>
    );
};

export default ResponseSummary;
