// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconDashboard,
    IconCreditCard,
    IconUser,
    IconChartBar,
    IconCurrencyDollar,
    IconGitPullRequest,
    IconClipboardList,
    IconPlus,
    IconShield,
    IconTemplate,
    IconBoxMultiple,
    IconLock,
    IconSettings,
    IconShieldCheck,
    IconNote
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconDashboard,
    IconChartBar,
    IconCreditCard,
    IconCurrencyDollar,
    IconUser,
    IconGitPullRequest,
    IconClipboardList,
    IconPlus,
    IconShield,
    IconTemplate,
    IconBoxMultiple,
    IconLock,
    IconShieldCheck,
    IconSettings,
    IconNote
};

// eslint-disable-next-line no-unused-expressions
const Menus = [
    {
        id: 'dashboard',
        mainMenu: true,
        menuTitle: 'Dashboard',
        type: 'item',
        url: '/',
        icon: icons.IconDashboard,
        breadcrumbs: false
    },
    {
        id: 'surveys',
        mainMenu: true,
        menuTitle: 'Surveys',
        type: 'item',
        url: '/surveys',
        icon: icons.IconNote,
        breadcrumbs: true
    },
    {
        id: 'profile',
        mainMenu: true,
        menuTitle: 'Profile',
        type: 'item',
        url: '/profile',
        icon: icons.IconUser,
        breadcrumbs: false,
        submenus: [
            {
                id: 'profile',
                title: <FormattedMessage id="My Account" />,
                type: 'item',
                url: '/profile',
                icon: icons.IconBoxMultiple,
                breadcrumbs: false
            },
            {
                id: 'change-password',
                title: <FormattedMessage id="Password" />,
                type: 'item',
                url: '/profile/change-password',
                icon: icons.IconLock,
                breadcrumbs: false
            },
            {
                id: 'settings',
                title: <FormattedMessage id="Settings" />,
                type: 'item',
                url: '/profile/settings',
                icon: icons.IconSettings,
                breadcrumbs: false
            },
            {
                id: 'membership',
                title: <FormattedMessage id="Membership" />,
                type: 'item',
                url: '/profile/membership',
                icon: icons.IconShield,
                breadcrumbs: false
            }
        ]
    },
    // {
    //     id: 'payment',
    //     mainMenu: true,
    //     menuTitle: 'Payment',
    //     type: 'item',
    //     url: '/payment',
    //     icon: icons.IconCreditCard,
    //     breadcrumbs: false,
    //     submenus: [
    //         {
    //             id: 'payment-menu-one',
    //             title: <FormattedMessage id="History" />,
    //             type: 'item',
    //             url: '/payments/list',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'payment-menu-two',
    //             title: <FormattedMessage id="Methods" />,
    //             type: 'item',
    //             url: '/payments/list',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'payment-menu-three',
    //             title: <FormattedMessage id="Add Balance" />,
    //             type: 'item',
    //             url: '/payments/list',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'my-requests',
        mainMenu: true,
        menuTitle: 'My Requests',
        type: 'item',
        url: '/my-requests',
        icon: icons.IconGitPullRequest,
        breadcrumbs: false
    },
    {
        id: 'admin',
        mainMenu: true,
        menuTitle: 'Admin',
        type: 'item',
        url: '/admin',
        role: 'Super Admin',
        icon: icons.IconShieldCheck,
        breadcrumbs: false
    }
];

export default Menus;
