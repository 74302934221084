import React, { useState, useEffect } from 'react';
import {
    Button,
    Grid,
    Card,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    CardContent,
    CardActions,
    Typography,
    Box,
    Skeleton,
    Chip,
    useTheme,
    useMediaQuery,
    CardMedia
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SurveyService from 'services/survey.service';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CategoryIcon from '@mui/icons-material/Category';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out',
    border: `2px solid ${theme.palette.divider}`,
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[2]
    }
}));

const StyledCardContent = styled(CardContent)(() => ({
    flexGrow: 1
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5)
}));

const TemplateCard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [templates, setTemplates] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 8;

    const fetchCategories = async () => {
        try {
            const { data } = await SurveyService.getTemplateCategories();
            setCategories(data.categories || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchTemplates = async (tag = '', resetPage = false) => {
        setIsLoading(true);
        try {
            const currentPage = resetPage ? 0 : page;
            const response = await SurveyService.getTemplate(tag, currentPage * limit, limit);
            const newTemplates = response.data.data || [];

            if (resetPage) {
                setTemplates(newTemplates);
                setPage(0);
            } else {
                setTemplates((prev) => [...prev, ...newTemplates]);
            }

            setHasMore(newTemplates.length === limit);
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTagChange = async (tag) => {
        setSelectedTag(tag);
        await fetchTemplates(tag, true);
    };

    const loadMore = () => {
        setPage((prev) => prev + 1);
        fetchTemplates(selectedTag);
    };

    useEffect(() => {
        fetchCategories();
        fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createShortcut = (text, limit) => (text?.length > limit ? `${text.slice(0, limit - 3)}...` : text);

    const renderLoadingSkeletons = () => (
        <Grid container spacing={3}>
            {[...Array(4)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const renderTemplateCards = () => (
        <>
            <Grid container spacing={3}>
                {templates.map((survey) => (
                    <Grid item xs={12} sm={6} md={3} key={survey?.id}>
                        <Card
                            style={{
                                cursor: 'pointer',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                }
                            }}
                            onClick={() => console.log(`Selected template: ${survey?.survey_title}`)}
                        >
                            <div
                                style={{
                                    height: '160px'
                                    // background: template.gradient
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={survey?.meta?.template_cover_imageimage ?? ''}
                                    alt={survey?.title}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                        padding: '16px'
                                    }}
                                />
                            </div>
                            <CardContent style={{ padding: '16px' }}>
                                <Typography
                                    style={{
                                        fontSize: '0.875rem',
                                        fontWeight: 500,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {survey?.survey_title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {hasMore && (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button onClick={loadMore} variant="outlined" disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Load More'}
                    </Button>
                </Box>
            )}
        </>
    );

    const renderContent = () => {
        if (isLoading && templates.length === 0) {
            return renderLoadingSkeletons();
        }
        if (templates.length > 0) {
            return renderTemplateCards();
        }
        return <Typography variant="body1">No templates found.</Typography>;
    };

    return (
        <Grid container sx={{ width: '100%', m: 0 }}>
            {/* <Typography variant="h3" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
                Get started with readymade survey templates
                <GlobalModalHelpButton
                    title="Get started with readymade survey templates"
                    content="https://kb.surveyflip.com/getting-started/create-survey-from-template.html"
                />
            </Typography> */}

            <Grid container spacing={3}>
                {/* <Grid item xs={12} md={3} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Categories
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton selected={selectedTag === ''} onClick={() => handleTagChange('')}>
                                <ListItemText primary="All" />
                            </ListItemButton>
                        </ListItem>
                        {categories.map((category) => (
                            <ListItem disablePadding key={category.slug.en}>
                                <ListItemButton
                                    selected={selectedTag === category.slug.en}
                                    onClick={() => handleTagChange(category.slug.en)}
                                >
                                    <ListItemText primary={category.name.en} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid> */}

                <Grid item xs={12} md={9} lg={10}>
                    {renderContent()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TemplateCard;
