/* eslint-disable react/self-closing-comp */
import { Box, Button, Grid, Typography } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import Item from './Item';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import SurveyService from 'services/survey.service';
import { useParams } from 'react-router-dom';
import Style from './Style';
import QuestionOptions from './components/QuestionOptions';
import QuestionThemes from './components/QuestionThemes';
import QuestionBranching from './components/QuestionBranching';
import Loadable from 'ui-component/Loadable';
import snackcaseToPascalcase from 'utils/helperFunctions';
import AllQuestions from './components/AllQuestions';
import { first, isEmpty, isUndefined } from 'lodash';
import Emitter from 'utils/emitter';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'store';
import { activeItem, activeMainItem, openDrawer } from 'store/slices/menu';
import QuestionOptionSet from './components/QuestionOptionSet';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';

// ==============================|| Main Template ||============================== //
let SelectedQuestionComponent = '';

const CreateAdvanceSurvey = ({ survey, setSurvey }) => {
    const [formik, setFormik] = useState({});
    const dispatch = useDispatch();
    // const [survey, setSurvey] = useState(surveyInfo);
    const { surveyToken } = useParams();
    const selectedQuestionTypeIdentifier = useOnePageSurveyStore((state) => state.currentSelectedQuestionIdentifier);
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const SET_QUESTION_TYPES = useOnePageSurveyStore((state) => state.SET_QUESTION_TYPES);
    const SET_QUESTIONS = useOnePageSurveyStore((state) => state.SET_QUESTIONS);
    const SET_FIRST_QUESTION_AS_CURRENT_QUESTION = useOnePageSurveyStore((state) => state.SET_FIRST_QUESTION_AS_CURRENT_QUESTION);

    const RESET_CURRENT_QUESTION = useOnePageSurveyStore((state) => state.RESET_CURRENT_QUESTION);
    const currentlyEditingQuestionId = useOnePageSurveyStore((state) => state.currentlyEditingQuestionId);
    const allSurveyQuestion = useOnePageSurveyStore((state) => state.questions);

    const initialData = {
        question_title: '',
        question_info: '',
        is_active: true,
        required: true,
        meta: {
            is_active: true,
            welcome_screen: false,
            required: true,
            button_text: ''
        }
    };

    const formikSetter = (formik) => {
        setFormik(formik);
    };

    const fetchQuestionTypeData = async () => {
        const { data } = await SurveyService.getQuestionTypes(surveyToken);
        SET_QUESTION_TYPES(data);
    };

    const fetchAllQuestion = async () => {
        // if (!localStorage.getItem('onePageSurvey') || JSON.parse(localStorage.getItem('onePageSurvey'))?.state?.questions?.length === 0) {
        //     const { data } = await SurveyService.showQuestions(surveyToken);
        //     SET_QUESTIONS(data.data);
        // } else {
        //     const savedSurveyToken = first(JSON.parse(localStorage.getItem('onePageSurvey'))?.state?.questions)?.survey?.survey_token;

        //     if (savedSurveyToken === surveyToken) {
        //         SET_QUESTIONS(JSON.parse(localStorage.getItem('onePageSurvey'))?.state?.questions);
        //     } else {
        //         const { data } = await SurveyService.showQuestions(surveyToken);
        //         SET_QUESTIONS(data.data);
        //     }
        // }

        const localStorageData = JSON.parse(localStorage.getItem('onePageSurvey'))?.state?.questions;
        const savedSurveyToken = first(localStorageData)?.survey?.survey_token;

        if (
            !localStorageData ||
            localStorageData.length === 0 ||
            isUndefined(savedSurveyToken) ||
            (!isUndefined(savedSurveyToken) && savedSurveyToken !== surveyToken)
        ) {
            SET_QUESTIONS([]);
            RESET_CURRENT_QUESTION();

            localStorage.removeItem('onePageSurvey');

            const { data } = await SurveyService.showQuestions(surveyToken);
            SET_QUESTIONS(data.data);
            if (data.data?.length > 0) {
                SET_FIRST_QUESTION_AS_CURRENT_QUESTION();
            }
        } else {
            SET_QUESTIONS(localStorageData);
        }
    };

    useEffect(() => {
        if (selectedQuestionTypeIdentifier !== null) {
            SelectedQuestionComponent = Loadable(
                lazy(() => import(`views/questions/one-page-types/${snackcaseToPascalcase(selectedQuestionTypeIdentifier)}`))
            );
        }

        return () => {
            setFormik({});
            // setSurvey({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlyEditingQuestionId]);

    Emitter.on('QUESTION_TYPE_CHANGED_AFTER_DELETE', (questionType) => {
        SelectedQuestionComponent = Loadable(lazy(() => import(`views/questions/one-page-types/${snackcaseToPascalcase(questionType)}`)));
        return null;
    });

    // console.log('CREATE', formik.values);

    useEffect(() => {
        dispatch(activeMainItem(['dashboard']));
        dispatch(openDrawer(false));
        fetchQuestionTypeData();
        fetchAllQuestion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Style />
            <Grid container spacing={2}>
                <Grid
                    item
                    sm={12}
                    md={4}
                    lg={3}
                    xl={2}
                    sx={{
                        background: '#fff',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        padding: '0'
                    }}
                >
                    {formik && <AllQuestions formik={formik} />}
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={8}
                    lg={7}
                    xl={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background: '#fff',
                        padding: '16px',
                        paddingTop: '0px!important',
                        marginTop: '0px!important',
                        position: 'relative'
                    }}
                >
                    <Item sx={{ display: 'flex', alignSelf: 'flex-end', marginBottom: '10px' }}>
                        <QuestionOptionSet survey={survey} formik={formik} />
                    </Item>
                    <Item
                        style={{
                            borderRadius: '10px',
                            width: '100%',
                            padding: '25px',
                            minHeight: 'calc(100% - 120px)',
                            display: 'flex',
                            flexDirection: 'column'
                            // justifyContent: 'center'
                        }}
                    >
                        {SelectedQuestionComponent && allSurveyQuestion?.length > 0 && (
                            <SelectedQuestionComponent
                                formikSetter={formikSetter}
                                initialData={initialData}
                                questionMode="add"
                                survey={survey}
                            />
                        )}
                        {allSurveyQuestion?.length === 0 && <Typography variant="h1"> Add a Question</Typography>}
                    </Item>
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={12}
                    lg={2}
                    xl={2}
                    sx={{
                        background: '#f5f5f5',
                        boxShadow: 'inset -5px 0px 20px -10px #ddd',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        padding: '15px 10px'
                    }}
                >
                    <QuestionOptions survey={survey} formik={formik} />
                    <QuestionThemes survey={survey} setSurvey={setSurvey} />
                    {!isEmpty(survey) && (
                        <Item
                            style={{
                                border: '0'
                            }}
                        >
                            <Typography variant="h5" align="left">
                                Question Branching
                                <GlobalModalHelpButton
                                    title="Question Branching"
                                    content="https://kb.surveyflip.com/create-survey-content/question-branching.html"
                                />
                            </Typography>
                            <QuestionBranching survey={survey} variant="outlined" />
                        </Item>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

CreateAdvanceSurvey.propTypes = {
    survey: PropTypes.shape({
        survey_token: PropTypes.string,
        survey_theme: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
    }),
    setSurvey: PropTypes.func.isRequired
};

export default CreateAdvanceSurvey;
