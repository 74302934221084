/* eslint-disable react/self-closing-comp */
import { Box, Button, Card, CardContent, Grid, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { IconPlus, IconCirclePlus } from '@tabler/icons';
import QuestionTypes from './QuestionTypes';
import PropTypes from 'prop-types';

// ==============================|| Main Template ||============================== //

const QuestionTypeModal = ({ formik }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Add a Qustion" placement="right">
                <Button
                    sx={{
                        padding: '3px',
                        minWidth: 'auto',
                        float: 'right',
                        margin: '0',
                        background: 'transparent',
                        width: '24px',
                        height: '24px',
                        borderRadius: '100px',
                        fontSize: '18px',
                        fontWeight: '100',
                        border: '1px solid #616161',
                        color: '#222'
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <IconPlus size={18} />
                </Button>
            </Tooltip>
            {open ? <QuestionTypes open={open} onClose={() => setOpen(false)} formik={formik} /> : null}
        </>
    );
};

QuestionTypeModal.propTypes = {
    formik: PropTypes.any
};

export default QuestionTypeModal;
