/* eslint-disable react/self-closing-comp */
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Duplicate from './SaveComponents/Duplicate';
import SaveQuestion from './SaveComponents/SaveQuestion';
import Delete from './SaveComponents/Delete';
import Preview from './SaveComponents/Preview';
import { IconArrowLeft } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

// ==============================|| Main Template ||============================== //

const QuestionOptionSet = ({ survey, formik }) => {
    const navigate = useNavigate();
    return (
        <>
            <Preview showIcon showTitle={false} />
            <Delete showIcon showTitle={false} />
            <Duplicate showIcon showTitle={false} />
            <SaveQuestion formik={formik} />
        </>
    );
};

QuestionOptionSet.propTypes = {
    survey: PropTypes.object,
    formik: PropTypes.any
};

export default QuestionOptionSet;
