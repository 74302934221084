/* eslint-disable react/self-closing-comp */
import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Grid,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import Item from '../Item';
import { IconDotsVertical } from '@tabler/icons';
import QuestionTypeModal from '../QuestionTypeModal';
import { makeStyles } from '@mui/styles';
import { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionService from 'services/question.service';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Delete from './SaveComponents/Delete';
import Duplicate from './SaveComponents/Duplicate';

const useStyles = makeStyles((theme) => ({
    listItemSecondaryAction: {
        visibility: 'hidden'
    },
    ListItemButton: {
        '&:hover $listItemSecondaryAction': {
            visibility: 'visible'
        }
    }
}));

const AllQuestions = ({ formik }) => {
    const surveyQuestions = useOnePageSurveyStore((state) => state.questions);
    const SET_CURRENT_QUESTION_ID = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION_ID);
    const MUTATE_QUESTIONS_ORDER = useOnePageSurveyStore((state) => state.MUTATE_QUESTIONS_ORDER);
    const SET_SELECTED_QUESTION_IDENTIFIER = useOnePageSurveyStore((state) => state.SET_SELECTED_QUESTION_IDENTIFIER);
    const classes = useStyles();
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const questions = useOnePageSurveyStore((state) => state.questions);
    const SET_QUESTIONS = useOnePageSurveyStore((state) => state.SET_QUESTIONS);
    const { enqueueSnackbar } = useSnackbar();

    const { surveyToken } = useParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const [threeDotSelectedQuestion, setThreeDotSelectedQuestion] = useState({});

    const open = Boolean(anchorEl);
    const handleThreeDotsClick = (event, question) => {
        // event.preventDefault();
        event.stopPropagation();
        setThreeDotSelectedQuestion(question.id);
        SET_CURRENT_QUESTION_ID(question?.id);
        SET_SELECTED_QUESTION_IDENTIFIER(question?.question_type?.identifier);
        setAnchorEl(event.target);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setThreeDotSelectedQuestion({});
    };

    const updateOrder = async (sourceIndex, destinationIndex) => {
        const newQuestions = [...questions];
        const movedItem = newQuestions.splice(sourceIndex, 1)[0]; // Remove the item from source index
        newQuestions.splice(destinationIndex, 0, movedItem); // Insert the item at destination index

        // Update the order property of each item
        newQuestions.forEach((item, index) => {
            item.order = index + 1; // Fixing order with index
        });

        SET_QUESTIONS(newQuestions);
        enqueueSnackbar('Question order updated!', {
            variant: 'success',
            autoHideDuration: 3000
        });
        const alteringQuestions = newQuestions.map((question) => ({ id: question?.id, order: question.order }));
        const { data: response } = await QuestionService.massOrderUpdate(surveyToken, { questions: alteringQuestions });

        return newQuestions;
    };

    // Handle the drag end event
    const handleDragEnd = async (result) => {
        console.log('DnD', result);
        // let orderChangeQuestionsAfterSource = '';
        // let orderChangeQuestionsAfterDestination = '';

        const draggableCurrentQuestionId = Number(result.draggableId?.replace('id-', ''));
        const draggableCurrentQuestion = questions?.find((question) => question?.id === draggableCurrentQuestionId);
        const sourceQuestionOrder = result?.source?.index + 1;
        const destinationQuestionOrder = result?.destination?.index + 1;
        const prevDestinationQuestion = questions[result?.destination?.index];

        updateOrder(result?.source?.index, result?.destination?.index);

        // Questions order that need to be changed after source before Destination
        // const orderChangeQuestionsAfterSource = questions
        //     ?.filter((question) => question?.order > draggableCurrentQuestion?.order && question?.order <= prevDestinationQuestion?.order)
        //     ?.map((question) => ({ id: question?.id, order: question?.order - 1, title: question.question_title }));

        // // Questions order that need to be changed after Destination
        // const orderChangeQuestionsAfterDestination = questions
        //     ?.filter((question) => question?.order > prevDestinationQuestion?.order)
        //     ?.map((question) => ({ id: question?.id, order: question?.order + 1, title: question.question_title }));

        // const alteringQuestions = [
        //     ...orderChangeQuestionsAfterSource,
        //     ...orderChangeQuestionsAfterDestination,
        //     {
        //         id: draggableCurrentQuestion.id,
        //         order: prevDestinationQuestion?.order + 1
        //     }
        // ];

        // // console.log('Testing chole ', alteringQuestions, questions);
        // console.log('Testing chole ', orderChangeQuestionsAfterSource, orderChangeQuestionsAfterDestination, questions);

        // const { data: response } = await QuestionService.massOrderUpdate(surveyToken, { questions: alteringQuestions });

        // MUTATE_QUESTIONS_ORDER(alteringQuestions);
        // TODO: Update the state based on the result
    };

    // console.log('All Questions', formik.values);

    return (
        <Item>
            <Grid container spacing={2} sx={{ marginTop: '0' }}>
                <Grid item xs={8} sx={{ paddingTop: '0px!important' }}>
                    <Item sx={{ padding: '0' }}>
                        <Typography variant="h3">Questions</Typography>
                    </Item>
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: '0px!important' }}>
                    <Item sx={{ padding: '0' }}>
                        <QuestionTypeModal formik={formik} />
                    </Item>
                </Grid>
            </Grid>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <List
                            sx={{ height: 'calc(100vh - 150px)', overflowY: 'auto', listStyle: 'decimal' }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {surveyQuestions?.length > 0 &&
                                surveyQuestions
                                    ?.sort((a, b) => a.order - b.order)
                                    ?.map((question, index) => (
                                        <Draggable key={question.id} draggableId={`id-${question.id}`} index={index}>
                                            {(provided) => (
                                                <ListItemButton
                                                    sx={{
                                                        backgroundColor: currentQuestion?.id === question.id ? '#ede7f6' : 'transparent',
                                                        borderRadius: '4px',
                                                        marginTop: '8px',
                                                        height: '40px',
                                                        border: '1px solid rgba(0,0,0,.099)',
                                                        '&:hover .questionDots': {
                                                            visibility: 'visible'
                                                        }
                                                    }}
                                                    key={index}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ListItem
                                                        sx={{ display: 'list-item', marginLeft: '10px', padding: 0 }}
                                                        onClick={() => {
                                                            SET_CURRENT_QUESTION_ID(question?.id);
                                                            SET_SELECTED_QUESTION_IDENTIFIER(question?.question_type?.identifier);
                                                        }}
                                                    >
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        padding: '0 5% 0 0',
                                                                        lineHeight: '160%',
                                                                        textAlign: 'left',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}
                                                                >
                                                                    {question.question_title}{' '}
                                                                    {typeof question.id !== 'number' ? (
                                                                        <small style={{ color: 'red' }}>
                                                                            <em>(Unsaved)</em>
                                                                        </small>
                                                                    ) : null}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>

                                                    <ListItemSecondaryAction
                                                        sx={{ visibility: 'hidden', padding: 0 }}
                                                        className="questionDots"
                                                    >
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="comments"
                                                            id="demo-positioned-button"
                                                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={(event) => handleThreeDotsClick(event, question)}
                                                        >
                                                            <IconDotsVertical />
                                                        </IconButton>

                                                        <Menu
                                                            id="demo-positioned-menu"
                                                            aria-labelledby="demo-positioned-button"
                                                            anchorEl={anchorEl}
                                                            open={open && threeDotSelectedQuestion === question.id}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left'
                                                            }}
                                                        >
                                                            <MenuItem>
                                                                <Delete showIcon={false} showTitle />
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                <Duplicate showIcon={false} showTitle />
                                                            </MenuItem>
                                                        </Menu>
                                                    </ListItemSecondaryAction>
                                                </ListItemButton>
                                            )}
                                        </Draggable>
                                    ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </Item>
    );
};

AllQuestions.propTypes = {
    formik: PropTypes.any
};

export default AllQuestions;
