/* eslint-disable react/self-closing-comp */
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { Icon3dRotate, IconDashboard, IconPlus, IconReport, IconShare } from '@tabler/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import CreateAdvanceSurvey from './create';
import ChangePassword from 'views/account/profile/ChangePassword';
import Settings from 'views/account/profile/Settings';
import Membership from 'views/account/profile/Membership';
import Integration from './integration';
import SurveyService from 'services/survey.service';
import Share from './share';
import Report from '../report';
import { LoadingButton } from '@mui/lab';
import Item from './Item';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Overview from './overview';
import { useDispatch } from 'react-redux';
import { activeMainItem } from 'store/slices/menu';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box
                    sx={{
                        p: 0
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const tabsOption = [
    {
        label: 'Overview',
        icon: <IconDashboard sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=overview'
    },
    {
        label: 'Create',
        icon: <IconPlus sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=create'
    },
    {
        label: 'Integration',
        icon: <Icon3dRotate sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=integration'
    },
    {
        label: 'Share',
        icon: <IconShare sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=share'
    },
    {
        label: 'Report',
        icon: <IconReport sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=report'
    }
];

const Builder = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const [survey, setSurvey] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { surveyToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { SET_CURRENT_SURVEY } = useMasterSurveyResponseStore();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchSurveyinfo = async () => {
        const { data } = await SurveyService.show(surveyToken);
        SET_CURRENT_SURVEY(data);
        setSurvey(data);
    };

    const handlePreview = () => {
        window.open(`/#/preview/${survey?.meta?.short_code}`, '_blank', 'noreferrer');
    };

    const handleTab = () => {
        // Create a URLSearchParams object with the search string from the location object
        const queryParams = new URLSearchParams(location.search);

        // Get the value of the 'tab' parameter
        const tabValue = queryParams.get('tab');

        if (tabValue === 'overview') {
            setValue(0);
        }

        if (tabValue === 'integration') {
            setValue(2);
        }

        if (tabValue === 'share') {
            setValue(3);
        }

        if (tabValue === 'report') {
            setValue(4);
        }
    };

    const handlePublish = async () => {
        setIsLoading(true);
        let values = {};
        if (survey.survey_status === 'Development') {
            values = { ...survey, survey_status: 'Live' };
        } else {
            values = { ...survey, survey_status: 'Development' };
        }
        const { data: surveyData } = await SurveyService.update(survey.survey_token, values);
        SET_CURRENT_SURVEY(surveyData);

        if (surveyData.survey_status === 'Development') {
            setValue(0);
            enqueueSnackbar('Survey unpublished', { variant: 'success', autoHideDuration: 2500 });
        } else {
            setValue(3);
            enqueueSnackbar('Survey Published', { variant: 'success', autoHideDuration: 2500 });
        }
        setSurvey(surveyData);
        setIsLoading(false);

        // window.location.href = `${window.location.href}`;
    };

    useEffect(() => {
        handleTab();
        fetchSurveyinfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (survey.survey_status === 'Live') {
            const queryParams = new URLSearchParams(location.search);
            // Get the value of the 'tab' parameter
            const tabValue = queryParams.get('tab');
            if (tabValue !== 'integration' && tabValue !== 'overview' && tabValue !== 'share') {
                // window.location.href = `${window.location.href}?tab=integration`;
                // Go to Share tab
                setValue(3);
            }
        }
        if (survey.survey_status !== 'Live') {
            const queryParams = new URLSearchParams(location.search);
            const tabValue = queryParams.get('tab');

            if (tabValue === 'create') {
                setValue(1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [survey]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="Menu Options"
                    // variant="fullWidth"
                    className="advance-form-top-menu"
                    style={{ position: 'fixed', zIndex: '1101', width: 'calc(100% - 370px)', top: '0', left: '250px' }}
                >
                    {tabsOption?.map((tab, index) => (
                        <Tab
                            iconPosition="top"
                            key={index}
                            component={Link}
                            to={`${tab.url}`}
                            icon={tab.icon}
                            label={tab.label}
                            {...a11yProps(index)}
                            disabled={survey?.survey_status === 'Live' && tab.label === 'Create'}
                        />
                    ))}
                </Tabs>

                <Item
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'absolute',
                        zIndex: '1101',
                        top: '10px',
                        right: '120px'
                    }}
                >
                    <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={handlePreview}>
                        Preview
                    </Button>
                    <LoadingButton loading={isLoading} variant="contained" onClick={handlePublish} disabled={survey?.quota === 0}>
                        {survey.survey_status === 'Development' ? 'Publish' : 'Unpublish'}
                    </LoadingButton>
                </Item>

                <TabPanel value={value} index={0}>
                    {!isEmpty(survey) && <Overview survey={survey} setValue={setValue} setSurvey={setSurvey} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {!isEmpty(survey) && <CreateAdvanceSurvey survey={survey} setSurvey={setSurvey} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Integration />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Share survey={survey} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Report />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default Builder;
