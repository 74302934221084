/* eslint-disable */
import React, { useEffect } from 'react';
import { Alert, AlertTitle, Card, Typography, Grid, Button, Switch, Stack } from '@mui/material';
import ChartWrapper from './ChartWrapper';
import SurveyService from 'services/survey.service';
import { useParams, useNavigate } from 'react-router-dom';
import { IconReportAnalytics } from '@tabler/icons';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import ListSkeleton from 'views/ListSkeleton';

const DetailedReport = ({ responsesStarted }) => {
    const [toggle, setToggle] = React.useState(false);
    const { surveyToken } = useParams();
    const [response, setResponse] = React.useState('');
    const [lastUpdatedAt, setLastUpdatedAt] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleRequestReportSurvey = async () => {
        const response = await SurveyService.requestReportSurvey(surveyToken);
        enqueueSnackbar(response.data.msg, {
            variant: response.data.code,
            autoHideDuration: 3000
        });
        if (response.data.code !== 'error') {
            setTimeout(() => {
                location.href = '/#/my-requests';
            }, 3000);
        }
    };

    const handleChange = (event) => {
        setToggle(event.target.checked);
    };

    const fetchResponses = async () => {
        const resp = await SurveyService.getGraphResponse(surveyToken);
        setIsLoading(false);
        setResponse(resp.data.data);
        setLastUpdatedAt(resp.data.updated_at);
    };

    useEffect(() => {
        fetchResponses();
    }, []);

    return (
        <>
            {isLoading ? (
                <ListSkeleton number={10} />
            ) : (
                <Card>
                    <Button
                        startIcon={<IconReportAnalytics />}
                        variant="contained"
                        color="primary"
                        sx={{ mb: 3 }}
                        onClick={handleRequestReportSurvey}
                    >
                        Request for a Detailed Report
                    </Button>
                    {lastUpdatedAt !== '' && (
                        <Typography variant="h5" mb={2}>
                            Last Updated At: {dayjs(lastUpdatedAt).format('h:mm A, MMM D, YYYY')}
                        </Typography>
                    )}
                </Card>
            )}
            {response ? (
                <Card>
                    {response?.choices_report_data && Object.values(response?.choices_report_data).length !== 0 && (
                        <Grid container direction="column">
                            <Grid item alignSelf="flex-end">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Bar</Typography>
                                    <Switch onChange={handleChange} />
                                    <Typography>Pie</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                    {response &&
                        response.choices_report_data &&
                        Object.keys(response.choices_report_data).map((element, index) => (
                            <Grid container direction="row" mb={4} index={index}>
                                {/* <Grid item xs={1} justifySelf="flex-end">
                                    <Typography variant="h4" align="right">
                                        {`${index + 1}.***`}
                                    </Typography>
                                </Grid> */}

                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="h4" align="left">
                                                {`${index + 1}.${response?.questions.find((e) => e.id === Number(element)).question_title}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item mt={4}>
                                            <ChartWrapper
                                                choiceData={response?.choices_report_data[element]}
                                                questionData={response?.questions.find((e) => e.id === Number(element))}
                                                toggle={toggle}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Card>
            ) : (
                <>
                    {!isLoading && (
                        <Alert severity="error" variant="standard" mt={2}>
                            <AlertTitle>Info</AlertTitle>
                            Sorry, there are no reports generated for this survey yet. You can schedule a report by clicking the Request
                            button above.
                        </Alert>
                    )}
                </>
            )}
        </>
    );
};
export default DetailedReport;
